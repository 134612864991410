import React from 'react'
import "../css/Footer.css"

function Footer() {
  return (
    <footer>
      <p>2024 © Zaiat Oleksii. Design By Zaiat Oleksii</p>
    </footer>
  )
}

export default Footer
