import React from 'react';
import "../css/Pricing.css";

function Pricing() {
  return (
      <section id="pricing">
        <div className="wrapper">
          <h1>Ціни на послуги</h1>
          <table>
            <thead>
            <tr>
              <th>Найменування послуги</th>
              <th>Вартість послуги</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td data-label="Найменування послуги">Адвокат по господарським справам</td>
              <td data-label="Вартість послуги"></td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Постійне правове обслуговування юридичної особи (абонентське обслуговування юридичної особи)
              </td>
              <td data-label="Вартість послуги">від 5000 грн./місяць</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Постійне правове обслуговування підприємця (абонентське обслуговування фізичної особи-підприємця)
              </td>
              <td data-label="Вартість послуги">від 3000 грн./місяць</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Разова правова допомога</td>
              <td data-label="Вартість послуги"></td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Надання усної / письмової консультації</td>
              <td data-label="Вартість послуги">від 500 / 1000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Адвокатський запит</td>
              <td data-label="Вартість послуги">від 500 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Правова допомога у сфері ЗЕД (супроводження одного контракту)
              </td>
              <td data-label="Вартість послуги">від 5000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Ознайомлення з матеріалами справи в суді або інших державних органах, один виїзд
              </td>
              <td data-label="Вартість послуги">від 1500 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Представництво в суді (прибуття та/або участь в одному судовому засіданні)
              </td>
              <td data-label="Вартість послуги">від 2000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Представництво в державних органах (прибуття та/або участь в одному заході)
              </td>
              <td data-label="Вартість послуги">від 1500 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Представництво в органах Антимонопольного комітету України (прибуття та/або участь в одному заході)
              </td>
              <td data-label="Вартість послуги">від 2500 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Представництво перед юридичними або фізичними особами (прибуття та/або участь в одному заході)
              </td>
              <td data-label="Вартість послуги">від 2000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Правова допомога по законодавству про публічні закупівлі/тендерні спори (супроводження одного контракту/спору)
              </td>
              <td data-label="Вартість послуги">від 2500 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Складання правового документу (договір, лист, заява, вимога)</td>
              <td data-label="Вартість послуги">від 2000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Претензійна робота (один документ)</td>
              <td data-label="Вартість послуги">від 2000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист від рейдерського захоплення з боку партнерів (внутрішнє рейдерське захоплення), один день роботи
              </td>
              <td data-label="Вартість послуги">від 3000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист від рейдерського захоплення з боку сторонніх осіб (зовнішнє рейдерське захоплення), один день роботи
              </td>
              <td data-label="Вартість послуги">від 5000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Залучення допоміжного технічного персоналу для протидії рейдерському захопленню
              </td>
              <td data-label="Вартість послуги">за тарифікацією технічного персоналу</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Інші правові послуги за запитом Клієнта</td>
              <td data-label="Вартість послуги">за погодженням з Клієнтом</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Адвокат по кримінальним справам</td>
              <td data-label="Вартість послуги"></td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Надання усної / письмової консультації</td>
              <td data-label="Вартість послуги">від 500 / 1000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Адвокатський запит</td>
              <td data-label="Вартість послуги">від 500 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">Участь в обранні запобіжного заходу підозрюваному</td>
              <td data-label="Вартість послуги">від 3000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист на стадії досудового розслідування за злочином невеликої чи середньої тяжкості (ведення справи слідчим, детективом)
              </td>
              <td data-label="Вартість послуги">від 10000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист на стадії судового розслідування за злочином невеликої чи середньої тяжкості (розгляд справи судом)
              </td>
              <td data-label="Вартість послуги">від 15000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист на стадії досудового розслідування за тяжким злочином (ведення справи слідчим, детективом)
              </td>
              <td data-label="Вартість послуги">від 12000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист на стадії судового розслідування за тяжким злочином (розгляд справи судом)
              </td>
              <td data-label="Вартість послуги">від 18000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист на стадії досудового розслідування за особливо тяжким злочином (ведення справи слідчим, детективом)
              </td>
              <td data-label="Вартість послуги">від 15000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Захист на стадії судового розслідування за особливо тяжким злочином (розгляд справи судом)
              </td>
              <td data-label="Вартість послуги">від 20000 грн.</td>
            </tr>
            <tr>
              <td data-label="Найменування послуги">
                Інші види правової допомоги за запитом Клієнта (кримінальні справи)
              </td>
              <td data-label="Вартість послуги">за погодженням з Клієнтом</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
  );
}

export default Pricing;
